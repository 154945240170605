
import * as storyblokRuntime$geyojUmxqn from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.0.0-rc.2_rollup@4.21.3/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as vercelRuntime$IHhi02o4Cb from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.0.0-rc.2_rollup@4.21.3/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [
    "shopware.kippie.dev",
    "shopware.kippie.nl",
    "api.mapbox.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 90
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$geyojUmxqn, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['vercel']: { provider: vercelRuntime$IHhi02o4Cb, defaults: {} }
}
        